import React from "react";
import {css, useTheme} from "@emotion/react";
import {Collection} from "../../types/data";
import {mobileBreakpoint} from "../../constants";
import {topBarHeight} from "../../utils";
import {navigate} from "gatsby";


interface Props {
    activeCollection: Collection;
    collections: Collection[];
}

const CollectionSelector: React.FC<Props> = ({
                                                 activeCollection,
                                                 collections
                                             }) => {
    const theme = useTheme();
    const handleChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
        navigate(e.target.value)
    }
    return (
        <>
            <div
                css={css`
                  position: absolute;
                  left: ${theme.margin.main}px;
                  top: ${topBarHeight(theme) + theme.margin.main}px;
                  z-index: 10000;

                  @media (max-width: ${mobileBreakpoint}px) {
                    left: unset;
                    right: ${theme.margin.main * 2}px;
                    top: 50vh;
                    transform: rotate(-90deg);
                    transform-origin: top;
                  }
                `}>
                <select css={css`
                  font-family: ${theme.fontFamily};
                  font-size: ${theme.fontSize.main}px;
                  margin: 0;
                  border: 2px solid${theme.color.primary};
                  color: ${theme.color.primary};
                  padding: ${theme.margin.main}px ${theme.margin.main * 2}px;
                  border-radius: 25px;
                  background: white;
                  -moz-appearance:none; /* Firefox */
                  -webkit-appearance:none; /* Safari and Chrome */
                  appearance:none;
                  overflow: visible;
                  outline: none;
                `}
                onChange={handleChange}
                >
                    {collections.map((c, idx) => (
                        <option
                            key={idx}
                            value={c.pagePath}
                            selected={c.handle === activeCollection.handle}
                        >
                            {c.title}
                        </option>
                    ))}
                </select>
            </div>
        </>
    )
}

export default CollectionSelector
