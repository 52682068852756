import * as React from "react"
import {graphql} from "gatsby";
import {Collection, CollectionProductList} from "../types/data";
import CircularCarousel from "../components/Carousel/CircularCarousel";
import {css, useTheme} from "@emotion/react";
import Toggle from "../components/Toggle/Toggle";
import {mobileBreakpoint} from "../constants";
import ProductGrid from "../components/ProductGrid/ProductGrid";
import HomeTitle from "../components/HomeTitle/HomeTitle";
import {topBarHeight} from "../utils";
import {useGlobalState} from "../state";
import Responsive from "../components/Responsive/Responsive";
import MobileCarousel from "../components/Carousel/MobileCarousel";
import {SEO} from "../components/SEO/SEO";
import CollectionSelector from "../components/CollectionSelector/CollectionSelector";

interface Props {
    data: CollectionProductList
    pageContext: {
        collections: Collection[]
        activeCollection: Collection
    }
}


const CollectionTemplate = ({data, pageContext}: Props) => {
    const theme = useTheme();
    const [listView, setListView] = useGlobalState('listView')
    return (
        <main>
            {/* GRID TOGGLE */}
            <div css={css`
              position: absolute;
              right: ${theme.margin.main}px;
              top: ${topBarHeight(theme) + theme.margin.main}px;
              z-index: 10000;

              @media (max-width: ${mobileBreakpoint}px) {
                right: unset;
                left: ${theme.margin.main}px;
                top: 50vh;
                transform: rotate(90deg);
                transform-origin: top;
              }
            `}>
                <Toggle active={listView} onToggle={setListView}/>
            </div>

            <CollectionSelector
                activeCollection={pageContext.activeCollection}
                collections={pageContext.collections}
            />

            <HomeTitle/>
            {listView
                ? <ProductGrid items={data.shopifyCollection.products}></ProductGrid>
                : <Responsive
                    desktop={<CircularCarousel items={data.shopifyCollection.products}/>}
                    mobile={<MobileCarousel items={data.shopifyCollection.products}/>}/>
            }
        </main>
    )
}
export const pageQuery = graphql`
    query CollectionProductList($handle: String!) {
        shopifyCollection(handle: {eq: $handle}) {
            title
            products {
                title
                vendor
                handle
                id
                variants {
                    id
                    title
                    image {
                        gatsbyImageData
                    }
                }
            }
        }
    }
`


export default CollectionTemplate
export const Head = ({data, pageContext}: Props) => {
    return (<SEO title={pageContext.activeCollection.currentCollection ? undefined : data?.shopifyCollection?.title}/>)
}

